import React from "react"
import { find } from 'lodash';
import { Col, Container, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import Slider from 'react-slick';
import HeaderTwo from "../../../../components/headerTwo/headerTwo"
import Footer from "../../../../components/footer/footer"
import CanHelp from "../../../../components/Home/CanHelp/CanHelp"
import { getPropertyLink } from '../../../../utils';

import "../../../../scss/custom.scss";
import $ from "jquery";
import qs from "qs"
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "../../../../components/property-search/newhomesutils";
import algoliasearch from "algoliasearch/lite"
import {
    connectStats,
    SortBy,
    connectSortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    ToggleRefinement,
    connectRefinementList
} from "react-instantsearch-dom"
import {
    GoogleMapsLoader,
    GeoSearch,
    CustomMarker
} from 'react-instantsearch-dom-maps'
import Cookies from 'universal-cookie'
import { Link } from "@StarberryUtils";
import { navigate } from "@reach/router"
import { isMobile, isMobileOnly, browserName } from "react-device-detect";
import MapResultsDesktop from '../../../../components/property-search/mapview-desktop';
import NewhomeSeoResults from "../../../../components/property-search/newhomeseoResults"
import VideoPlay from "../../../../components/Play/property-results-video";
import FloorPlanModal from "../../../../components/property-search/floorPlanModal";
import { ShowProcessedImage } from '../../../../components/common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../../static/images/config.json"
import "../../assets/styles/_index.scss"
import BgShadowWhite from "../../../../images/bg-shadow-white2.svg";
import ComingSoonImage from "../../assets/img/coming-soon.jpg";
import SaveProperty from "../../../../components/property-search/saveProperty";
import SearchResultsFilterNewRentals from "../../../../components/search-results-filter/SearchResultsFilterNewRentals";
import { UserObjectStoreProvider } from "@myaccountServices";
import axios from "axios";
import { apiUrl, getToken } from '../../../../services/store/utils'

import { inViewOptions } from '../../../../components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import noPropertyData from "../../../../../static/nopropertydatarentalsnew.json"

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 60,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.1
    }
  }
}

const containerItem = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.1
    }
  }
}

/* Get User Objects*/
const baseURL = apiUrl()
const token = getToken()

// With header call
const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const getUserObjects = () => {
  return authInstance.get(`/stb-users/userobjects`);
}

const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

const searchClient = algoliasearch(
    "JXN4DTCGY8",
    "f66d5f8f7879f856bd74c84c800d47cf"
)

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

// Active filter onscroll
let lastScrollY = 0
let activeHeader = ""
// Active filter onscroll

// Capitalize
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
// Capitalize

// Property results loop
const InfiniteHits = ({
    hits,
    hasPrevious,
    refinePrevious,
    hasMore,
    refineNext,
    location,
    userObjects
}) => (
    <>
      <UserObjectStoreProvider>
        <>
          {(hits.length > 0 && hasPrevious) && (
              <button
                  id="myprevbut"
                  onClick={refinePrevious}
                  disabled={!hasPrevious}
                  className="d-none property_show_more_btn"
              >
                  Show prev
              </button>
          )}
        </>

        {
            hits.map((hit, i) => {

                const settings = {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: true,
                    mobileFirst:true,
                    
                    responsive: [
                        {
                        breakpoint: 767,
                        settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: false,
                                dots: false,
                                arrows: true,
                            }
                        },
                        {
                            breakpoint: 9999,
                            settings: "unslick"
                        }
                    ]
                }

                // property details url structure
                let uriStr = "";

                if(hit.search_type === "lettings" ) {
                    uriStr = `new-homes-to-rent/`
                }
                // property details url structure
                let propertyUrl = getPropertyLink(uriStr, hit);

                //property description
                let text_truncate = function(str, length, ending) {
                  //
                  var $win = $(window);

                  if ( $win.width() <= 1500 ) {
                      if (length == null) {
                          length = 100;
                      }
                  } else if ( $win.width() >= 1500 ) {
                      if (length == null) {
                          length = 220;
                      }
                  }
                  //

                  if (ending == null) {
                    ending = '...';
                  }
                  if (str.length > length) {
                    return str.substring(0, length - ending.length) + ending;
                  } else {
                    return str;
                  }
                };
                // property description

                return (
                  // <InView {...inViewOptions}>
                  //   {({ ref, inView }) => (
                    <>
                        {
                          // <motion.div
                          //   className="property-card d-md-flex flex-wrap mysingleproperty position-relative"
                          //   id={`myRentProp${hit.objectID}`} key={i}
                          //   data-objectid={hit.objectID} data-lat={hit?._geoloc?.lat} data-lng={hit?._geoloc?.lng}
                          //   ref={ref}
                          //   initial="hidden"
                          //   animate={inView ? "visible" : "hidden"}
                          //   variants={containerItem}
                          // >
                          <div className="property-card d-md-flex flex-wrap mysingleproperty position-relative"
                            id={`myRentProp${hit.objectID}`} key={i}
                            data-objectid={hit.objectID} data-lat={hit?._geoloc?.lat} data-lng={hit?._geoloc?.lng}
                          >
                          { (hit.status == "Sold" || hit.status == "Let" || hit.status == "Under Offer") &&
                            <div class="position-absolute text-white my-2 property-flag">{hit.status  == "Under Offer" ? `SOLD STC` : hit.status}</div>
                          }
                            <div className="view-part1">
                              <Slider className="main-slider" {...settings} >
                                  <div className="main-item">
                                      <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                      to={propertyUrl}
                                      propID={hit.objectID}
                                      state={{ referrer: location.pathname }}>
                                        {
                                          hit.images1 ? <ShowProcessedImage images={hit.images1[0]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults1.sizes} />
                                          :
                                          <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                        }
                                      </Link>
                                      <div className="on-map d-flex">
                                          <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                          to={`${propertyUrl}?id=propertymap` }><i className="icon-location"></i> <span className="exp-txt text-uppercase d-md-block d-none">View on Map</span></Link>
                                          {hit.floorplan && JSON.stringify(hit.floorplan) !== '{}' ? <FloorPlanModal floorplanimg={hit.floorplan.url} /> : ''}
                                      </div>
                                  </div>
                                  <div className="main-item d-md-none">
                                      <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                      to={propertyUrl}
                                      propID={hit.objectID}
                                      state={{ referrer: location.pathname }}>
                                        {
                                          hit.images2 && hit.images2.length > 0 ? <ShowProcessedImage images={hit.images2[0]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults2.sizes} />
                                          :
                                          <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                        }
                                      </Link>
                                      {/* <Link className="play-btn" to="#"><i className="icon-play-icon"></i></Link> */}
                                      {(hit.virtual_tour ? ((hit.virtual_tour.length > 0) ? <VideoPlay url={hit.virtual_tour}/> : ''):'')}
                                  </div>
                                  <div className="main-item d-md-none">
                                      <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                      to={propertyUrl}
                                      propID={hit.objectID}
                                      state={{ referrer: location.pathname }}>
                                        {
                                          hit.images && hit.images2.length > 0 ? <ShowProcessedImage images={hit.images2[1]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults2.sizes} />
                                          :
                                          <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                        }
                                      </Link>
                                  </div>
                              </Slider>
                            </div>
                            <div className="view-part2 d-none d-md-block">
                              <div className="main-item">
                                  <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                  to={propertyUrl}
                                  propID={hit.objectID}
                                  state={{ referrer: location.pathname }}>
                                    {
                                      hit.images2 && hit.images2.length > 0 ? <ShowProcessedImage images={hit.images2[0]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults2.sizes} />
                                      :
                                      <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                    }
                                  </Link>
                                  {/* <Link className="play-btn" to="#"><i className="icon-play-icon"></i></Link> */}
                                  {(hit.virtual_tour ? ((hit.virtual_tour.length > 0) ? <VideoPlay url={hit.virtual_tour}/> : ''):'')}
                              </div>
                              <div className="main-item">
                                  <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                  to={propertyUrl}
                                  propID={hit.objectID}
                                  state={{ referrer: location.pathname }}>
                                    {
                                      hit.images2 && hit.images2.length > 0 ? <ShowProcessedImage images={hit.images2[1]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults2.sizes} />
                                      :
                                      <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                    }
                                  </Link>
                              </div>
                            </div>
                            <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                              to={propertyUrl}
                              propID={hit.objectID}
                              state={{ referrer: location.pathname }}
                              className="card-details d-flex align-items-center"
                            >
                              <div className="w-100">
                                <div className="detail-head d-flex align-items-center justify-content-between">
                                  <div className="sec-title price-title">  
                                    {
                                      hit.price_qualifier === "POA" ? hit.price_qualifier : (hit.price ? <><span className="price_qua_price">&pound;{hit.price.toLocaleString()} {hit.sale_maxprice ? <> - &pound;{hit.sale_maxprice.toLocaleString()}</> : ''}</span></> : '')
                                    }
                                  </div>
                                  
                                    {/* { hit.price ? <h2 className="price">&pound;{hit.price.toLocaleString()} {hit.sale_maxprice ? <> - &pound;{hit.sale_maxprice.toLocaleString()}</> : ''} pcm</h2> : '' } */}
                                  {/* <SaveProperty type="property" pid={hit.objectID} userObjects={userObjects}/> */}
                                </div>
                                  <span className="address-title d-block">
                                      { hit.display_address ? hit.display_address : '' }
                                  </span>
                                  {hit.max_bedrooms ? <span className="prop-title d-block">{hit.bedroom +' - '+ hit.max_bedrooms +' bedrooms available'}</span> : hit.title ? <span className="prop-title d-block">{hit.title}</span> : '' }
                                  {/* { hit.title ? <span className="prop-title d-block">{hit.title}</span> : '' } */}
                                  { hit.description ? <p className="prop-desc d-none d-md-block mb-0">{text_truncate(hit.description)}</p> : '' }
                              </div>
                            </Link>
                            <SaveProperty type="property" pid={hit.objectID} userObjects={userObjects}/>
                          {/* </motion.div> */}
                          </div>
                        }

                        {
                            ((i + 1) % 6) === 0 ?
                            // <motion.div
                            //   className="property-card get-register d-lg-flex justify-content-between align-items-center"
                            //   ref={ref}
                            //   initial="hidden"
                            //   animate={inView ? "visible" : "hidden"}
                            //   variants={containerItem}
                            // >
                            <div className="property-card get-register d-lg-flex justify-content-between align-items-center">
                                <h2 className="sec-title">Register with My MG to get <span>first look<img loading="lazy" className="shadow-img shadow2" src={BgShadowWhite} alt="underline"/></span> at new properties before they’re released to Rightmove and Zoopla. </h2>
                                <a href={myaccountURL} className="btn btn-default">get started</a>
                            </div>
                            : ''
                        }
                    </>
                  //   )}
                  // </InView>
                )
            })
        }
{(hits.length > 0 && hasMore) && (
        <Container className="px-3">
          <Row>
            <Col lg={12} className="text-center load-more-btn px-0">
              {(hits.length > 0 && hasMore) && (
              <button
                  id="mynextbut"
                  onClick={refineNext}
                  disabled={!hasMore}
                  className="btn w-100"
              >
                  Load more
              </button>
              )}
            </Col>
          </Row>
        </Container>
    )}
      </UserObjectStoreProvider>
    </>
)

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)
// Property results loop

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine}) => {
    return (
      <Form.Group className="street-addr d-flex justify-content-between align-items-center">
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00004 0.421875C3.37023 0.421875 0.41626 3.375 0.41626 7.00566C0.41626 8.47884 0.919979 9.92756 1.83657 11.0866L7.00004 17.5781L12.1658 11.0835C13.0801 9.92756 13.5838 8.47884 13.5838 7.00566C13.5838 3.375 10.6307 0.421875 7.00004 0.421875ZM7.00004 9.45984C5.64723 9.45984 4.54585 8.35847 4.54585 7.00566C4.54585 5.652 5.64723 4.55147 7.00004 4.55147C8.3537 4.55147 9.45423 5.65228 9.45423 7.00566C9.45423 8.35847 8.3537 9.45984 7.00004 9.45984Z" fill="#575757"/>
        </svg>
          <Form.Control type="text" placeholder="Street, area or postcode"
            onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").replace(/,/g, "-").replace(/'/g, "").toLowerCase())}
            value={currentRefinement.replace(/-/g, ' ')}
          />
      </Form.Group>
    )
})
// Searchbox

// Property Min Price
const priceminRange = [
    {
      value: "0",
      label: "No Min"
    },
    {
      value: "100",
      label: "£100 pcm"
    },
    {
      value: "200",
      label: "£200 pcm"
    },
    {
      value: "300",
      label: "£300 pcm"
    },
    {
      value: "400",
      label: "£400 pcm"
    },
    {
      value: "500",
      label: "£500 pcm"
    },
    {
      value: "750",
      label: "£750 pcm"
    },
    {
      value: "1000",
      label: "£1,000 pcm"
    },
    {
      value: "2000",
      label: "£2,000 pcm"
    },
    {
      value: "3000",
      label: "£3,000 pcm"
    },
    {
      value: "4000",
      label: "£4,000 pcm"
    },
    {
      value: "5000",
      label: "£5,000 pcm"
    },
    {
      value: "10000",
      label: "£10,000 pcm"
    }
  ]

  const minpriceSelectBox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
  }) => {
    let minPriceRange = priceminRange;
    if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
      minPriceRange = priceminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
    }

    return (
      <div className="select-group">
        <Select
            name="price"
            options={minPriceRange}
            defaultValue={currentRefinement.min || "0"}
            placeholder={currentRefinement.min === undefined || null || 0 ? "Min Price" : "£"+currentRefinement.min.toLocaleString()+" pcm"}
            classNamePrefix={"select-opt"}
            isSearchable={false}
            onChange={(e) => {
                minpriceval = e.value
                if (e.value === "0") {
                    if (maxpriceval) {
                        refine({
                            max: maxpriceval,
                        })
                    } else refine(e.value, e.label)
                } else {
                    refine({
                        min: e.value,
                        max: maxpriceval,
                    })
                }
            }}
        />
      </div>
    )
  }

const CustomminpriceSelectbox = connectRange(minpriceSelectBox)
// Property Min Price

// Property Max Price
const pricemaxRange = [
    {
        value: "0",
        label: "No Max"
    },
    {
      value: "10000",
      label: "£10,000 pcm +"
    },
    {
      value: "5000",
      label: "£5,000 pcm"
    },
    {
      value: "4000",
      label: "£4,000 pcm"
    },
    {
      value: "3000",
      label: "£3,000 pcm"
    },
    {
      value: "2000",
      label: "£2,000 pcm"
    },
    {
      value: "1000",
      label: "£1,000 pcm"
    },
    {
      value: "750",
      label: "£750 pcm"
    },
    {
      value: "500",
      label: "£500 pcm"
    },
    {
      value: "400",
      label: "£400 pcm"
    },
    {
      value: "300",
      label: "£300 pcm"
    },
    {
      value: "200",
      label: "£200 pcm"
    },
    {
      value: "100",
      label: "£100 pcm"
    }
  ]

  const maxpriceSelectBox = ({
      currentRefinement,
      min,
      max,
      precision,
      refine,
  }) => {

      let maxPriceRange = pricemaxRange;
      let maxpricedisable = ''
      if (currentRefinement.min !== undefined) {
        maxPriceRange = pricemaxRange.filter(x => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min))
      }
      if (currentRefinement.min == "10000000") {
        maxpricedisable = "disabled"
      }

      return(
        <div className="select-group">
          <Select
              name="price"
              options={maxPriceRange}
              defaultValue={currentRefinement.max || "0"}
              placeholder={currentRefinement.max === undefined || null || 0 ? "Max Price" : "£"+currentRefinement.max.toLocaleString()+" pcm"}
              classNamePrefix={"select-opt"}
              isSearchable={false}
              onChange={(e) => {
                  maxpriceval = e.value
                  if (e.value === "0") {
                      if (minpriceval) {
                          refine({
                              min: minpriceval,
                          })
                      } else refine(e.value, e.label)
                  } else {
                      refine({
                          min: minpriceval,
                          max: e.value,
                      })
                  }
              }}
          />
        </div>
      )
  }

const CustommaxpriceSelectbox = connectRange(maxpriceSelectBox)
// Property Max Price

// Property Bedrooms
const bedroomSelectBox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
  }) => {

    let bedroomvalselect = ""
    if (currentRefinement.max !== undefined) {
      bedroomvalselect = 0
    } else if (currentRefinement.min !== undefined) {
      bedroomvalselect = currentRefinement.min
    }

    return(
        <Select
            name="price"
            options={[
              { value: "", label: "No Min" },
              { value: '1', label: '1 +' },
              { value: '2', label: '2 +' },
              { value: '3', label: '3 +' },
              { value: '4', label: '4 +' },
              { value: '5', label: '5 +' },
              { value: '6', label: '6 +' }
            ]}
            placeholder={currentRefinement.min === undefined || null || 0 ? "Bedrooms" : currentRefinement.min+" +"}
            classNamePrefix={"select-opt"}
            isSearchable={false}
            onChange={(e) => {
                if (e.value === "") {
                    refine(e.value, e.label)
                } else if (e.value === "0") {
                    refine({
                        max: e.value,
                    })
                } else {
                    refine({
                        min: e.value,
                    })
                }
            }}
        />
    )
  }

const CustombedroomSelectbox = connectRange(bedroomSelectBox)
// Property Bedrooms

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine }) => (
    <Select
      name="building"
      options={[
        { value: "", label: "All Properties" },
        { value: "apartment", label: "Flat/Apartment" },
        { value: "house", label: "All Houses" },
        { value: "semi-detached-house", label: "House (Semi-detached)" },
        { value: "bungalow", label: "Bungalows" },
        { value: "new-home", label: "New Homes" },
        { value: "terrace", label: "Terrace" },
        { value: "land", label: "Land" },
      ]}
      placeholder={currentRefinement === null || undefined || "" ? "Property Type" : capitalize(currentRefinement).replace(/-/g, " ")}
      classNamePrefix={"select-opt"}
      isSearchable={false}
      onChange={(e) => {refine(e.value, e.label)}}
    />
)

const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Maps
const MapResultsDevice = (() => {
  return (
     <>
      <div id="map" className="map">
        <GoogleMapsLoader apiKey={process.env.GATSBY_MAPS_API_KEY}>
          {google => <MapResults1 google={google} />}
        </GoogleMapsLoader>
      </div>
    </>
  )
})
var InfoWindow;
var onClickMarker;
var html;

const MapResults1 = ((props) => {
  InfoWindow = new props.google.maps.InfoWindow();

  onClickMarker = ({ hit, marker }) => {

    if (InfoWindow.getMap()) {
      InfoWindow.close();
    }


    // property details url structure
    let uriStr = "";

    if(hit.search_type === "lettings" ) {
      uriStr = 'new-homes-to-rent/'
    }
    // property details url structure
    let propertyUrl = getPropertyLink(uriStr, hit);

    // available days
    let currFullDate = new Date();
    let currYear = currFullDate.getFullYear();
    let currMonth = currFullDate.getMonth() +1;
    let currDate = currFullDate.getDate();
    let currDateCal = currYear+"-"+currMonth+"-"+currDate;
    let propertyDate = hit.AvailableFrom;
    let availableFrom = "";
    let date1 = new Date(propertyDate).getTime();
    let date2 = new Date(currDateCal).getTime();

    if(date1 > date2) {
      var timeDiff = Math.abs(date1 -date2);
      // days difference
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      availableFrom = diffDays;
    } else { }
    // available days

    // display address
    var propertyAddressFinal = ""

    if (hit.display_address === undefined ) {
      propertyAddressFinal = hit.display_address;
    } else {
      var propertyAddress = hit.display_address;
      var propertySplit = propertyAddress.split(',');

      for(var i = 0; i < propertySplit.length; i++) {
        // Trim the excess whitespace.
        propertySplit[i] = propertySplit[i].replace(/^\s*/, "").replace(/\s*$/, "");
        // Add additional code here, such as:
        propertyAddressFinal = propertySplit[0];
      }
    }

    html = '<a href="' + propertyUrl + '"><div class="map-property-card"><div class="slider"><img loading="lazy" style="width:100%; height:auto;" src="'+hit.images1[0]["326x218"]+'" /></div><div class="content"><h5>'+hit.display_address+'</h5><p>'+hit.title+'</p><p class="price">£'+hit.price.toLocaleString()+'</p></div></div></a>';

    InfoWindow.setContent(html);

    InfoWindow.open(marker.getMap(), marker);
  };
  return (
     <>
     {/* {isMobile && this.state.mapview &&
        <Configure
           hitsPerPage={2000}
        />
      } */}
          <GeoSearch google={props.google} >
              {({ hits }) => (
                  <div>
                      {hits.map(hit => (
                          <>
                              <CustomMarker
                                  anchor={{ x: 0, y: -5 }}
                                  key={hit.objectID}
                                  position={hit._geoloc}
                                  hit={hit}
                                  className={"icon-property_map_marker map-marker-"+ hit.objectID}
                                  onClick={({ marker }) => {
                                      onClickMarker({ hit, marker, });
                                  }}
                              />
                          </>
                      ))}
                  </div>
              )}
          </GeoSearch>
    </>
  )
})
// Maps

// No Results
const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

const NoStats = ({ processingTimeMS, nbHits, userObjects, location }) => {

  var image1;
  var image2;
  var image3;

   return (
        <>
        {nbHits === 0 &&
            <Row className="justify-content-left">
                <Col lg={12} className="text-left mb-5">
                  <div className="no-results-text">
                    <p className="font-weight-bold">
                      Unfortunately, we do not currently have any properties that match your search criteria.
                    </p>
                    <p>We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the search bar above.
                    </p>
                  </div>
    <div>
      <UserObjectStoreProvider>
        {
            noPropertyData.map((hit, i) => {
hit.objectID = hit._id

                const settings = {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: true,
                    mobileFirst:true,
                    responsive: [
                        {
                        breakpoint: 767,
                        settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: false,
                                dots: false,
                                arrows: true,
                            }
                        },
                        {
                            breakpoint: 9999,
                            settings: "unslick"
                        }
                    ]
                }

                // property details url structure
                let uriStr = "";

                if(hit.search_type === "lettings" ) {
                    uriStr = `new-homes-to-rent/`
                }
                // property details url structure
                let propertyUrl = getPropertyLink(uriStr, hit);

                //property description
                let text_truncate = function(str, length, ending) {
                  //
                  var $win = $(window);

                  if ( $win.width() <= 1500 ) {
                      if (length == null) {
                          length = 100;
                      }
                  } else if ( $win.width() >= 1500 ) {
                      if (length == null) {
                          length = 220;
                      }
                  }
                  //

                  if (ending == null) {
                    ending = '...';
                  }
                  if (str.length > length) {
                    return str.substring(0, length - ending.length) + ending;
                  } else {
                    return str;
                  }
                };
                // property description
                if (hit.images.length) {
                    hit.images[0].url = hit.images[0].url.replace("i.prod","x.prod")
                    hit.images[1].url = hit.images[1].url.replace("i.prod","x.prod")
                    hit.images[2].url = hit.images[2].url.replace("i.prod","x.prod")

                    hit.images[0].url = hit.images[0].url.replace("i.dev","x.dev")
                    hit.images[1].url = hit.images[1].url.replace("i.dev","x.dev")
                    hit.images[2].url = hit.images[2].url.replace("i.dev","x.dev")

                    image1 = {'326x218': hit.images[0].url.replace("/images/","/images/326x218/"), '457x310': hit.images[0].url.replace("/images/","/images/457x310/"), '600x400': hit.images[0].url.replace("/images/","/images/600x400/"), '415x277': hit.images[0].url.replace("/images/","/images/415x277/") };

                    image2 = {'232x155': hit.images[1].url.replace("/images/","/images/232x155/"), '300x200': hit.images[1].url.replace("/images/","/images/300x200/"), '326x218': hit.images[1].url.replace("/images/","/images/326x218/") };

                    image3 = {'232x155': hit.images[2].url.replace("/images/","/images/232x155/"), '300x200': hit.images[2].url.replace("/images/","/images/300x200/"), '326x218': hit.images[2].url.replace("/images/","/images/326x218/") };
                }
                return (
                  // <InView {...inViewOptions}>
                  //   {({ ref, inView }) => (
                    <>
                        {
                          // <motion.div
                          //   className="property-card d-md-flex flex-wrap mysingleproperty position-relative"
                          //   id={`myRentProp${hit.objectID}`} key={i}
                          //   data-objectid={hit.objectID} data-lat={hit?._geoloc?.lat} data-lng={hit?._geoloc?.lng}
                          //   ref={ref}
                          //   initial="hidden"
                          //   animate={inView ? "visible" : "hidden"}
                          //   variants={containerItem}
                          // >
                          <div className="property-card d-md-flex flex-wrap mysingleproperty position-relative"
                            id={`myRentProp${hit.objectID}`} key={i}
                            data-objectid={hit.objectID} data-lat={hit?._geoloc?.lat} data-lng={hit?._geoloc?.lng}
                          >
                          { (hit.status == "Sold" || hit.status == "Let" || hit.status == "Under Offer") &&
                            <div class="position-absolute text-white my-2 property-flag">{hit.status  == "Under Offer" ? `SOLD STC` : hit.status}</div>
                          }
                            <div className="view-part1">
                              <Slider className="main-slider" {...settings} >
                                  <div className="main-item">
                                      <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                      to={propertyUrl}
                                      propID={hit.objectID}
                                      state={{ referrer: location.pathname }}>
                                        {
                                          image1 ? <ShowProcessedImage images={image1} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults1.sizes} />
                                          :
                                          <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                        }
                                      </Link>
                                      <div className="on-map d-flex">
                                          <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                          to={`${propertyUrl}?id=propertymap` }><i className="icon-location"></i> <span className="exp-txt text-uppercase d-md-block d-none">View on Map</span></Link>
                                          {hit.floorplan && JSON.stringify(hit.floorplan) !== '{}' ? <FloorPlanModal floorplanimg={hit.floorplan.url} /> : ''}
                                      </div>
                                  </div>
                                  <div className="main-item d-md-none">
                                      <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                      to={propertyUrl}
                                      propID={hit.objectID}
                                      state={{ referrer: location.pathname }}>
                                        {
                                          image2 && image2 ? <ShowProcessedImage images={image2} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults2.sizes} />
                                          :
                                          <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                        }
                                      </Link>
                                      {/* <Link className="play-btn" to="#"><i className="icon-play-icon"></i></Link> */}
                                      {(hit.virtual_tour ? ((hit.virtual_tour.length > 0) ? <VideoPlay url={hit.virtual_tour}/> : ''):'')}
                                  </div>
                                  <div className="main-item d-md-none">
                                      <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                      to={propertyUrl}
                                      propID={hit.objectID}
                                      state={{ referrer: location.pathname }}>
                                        {
                                          image3 && image3 ? <ShowProcessedImage images={image3} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults2.sizes} />
                                          :
                                          <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                        }
                                      </Link>
                                  </div>
                              </Slider>
                            </div>
                            <div className="view-part2 d-none d-md-block">
                              <div className="main-item">
                                  <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                  to={propertyUrl}
                                  propID={hit.objectID}
                                  state={{ referrer: location.pathname }}>
                                    {
                                      image2 && image2 ? <ShowProcessedImage images={image2} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults2.sizes} />
                                      :
                                      <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                    }
                                  </Link>
                                  {/* <Link className="play-btn" to="#"><i className="icon-play-icon"></i></Link> */}
                                  {(hit.virtual_tour ? ((hit.virtual_tour.length > 0) ? <VideoPlay url={hit.virtual_tour}/> : ''):'')}
                              </div>
                              <div className="main-item">
                                  <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                                  to={propertyUrl}
                                  propID={hit.objectID}
                                  state={{ referrer: location.pathname }}>
                                    {
                                      image3 && image3 ? <ShowProcessedImage images={image3} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults2.sizes} />
                                      :
                                      <img loading="lazy" src={ComingSoonImage} alt="Martyn Gerrard" />
                                    }
                                  </Link>
                              </div>
                            </div>
                            <Link onClick={() => {mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 });sessionStorage.setItem('searchindex', hit.objectID);}}
                              to={propertyUrl}
                              propID={hit.objectID}
                              state={{ referrer: location.pathname }}
                              className="card-details d-flex align-items-center"
                            >
                              <div className="w-100">
                                <div className="detail-head d-flex align-items-center justify-content-between">
                                    { hit.price ? <h2 className="price">&pound;{hit.price.toLocaleString()} {hit.sale_maxprice ? <> - &pound;{hit.sale_maxprice.toLocaleString()}</> : ''} {hit.price_qualifier}</h2> : '' }
                                  {/* <SaveProperty type="property" pid={hit.objectID} userObjects={userObjects}/> */}
                                </div>
                                  <span className="address-title d-block">
                                      { hit.display_address ? hit.display_address : '' }
                                  </span>
                                  {hit.max_bedrooms ? <span className="prop-title d-block">{hit.bedroom +' - '+ hit.max_bedrooms +' bedrooms available'}</span> : hit.title ? <span className="prop-title d-block">{hit.title}</span> : '' }
                                  {/* { hit.title ? <span className="prop-title d-block">{hit.title}</span> : '' } */}
                                  { hit.description ? <p className="prop-desc d-none d-md-block mb-0">{text_truncate(hit.description)}</p> : '' }
                              </div>
                            </Link>
                            <SaveProperty type="property" pid={hit.objectID} userObjects={userObjects}/>
                          {/* </motion.div> */}
                          </div>
                        }

                        {
                            ((i + 1) % 6) === 0 ?
                            // <motion.div
                            //   className="property-card get-register d-lg-flex justify-content-between align-items-center"
                            //   ref={ref}
                            //   initial="hidden"
                            //   animate={inView ? "visible" : "hidden"}
                            //   variants={containerItem}
                            // >
                            <div className="property-card get-register d-lg-flex justify-content-between align-items-center">
                                <h2 className="sec-title">Register with My MG to get <span>first look<img loading="lazy" className="shadow-img shadow2" src={BgShadowWhite} alt="underline"/></span> at new properties before they’re released to Rightmove and Zoopla. </h2>
                                <a href={myaccountURL} className="btn btn-default">get started</a>
                            </div>
                            : ''
                        }
                    </>
                  //   )}
                  // </InView>
                )
            })
        }

      </UserObjectStoreProvider>
    </div>
               </Col>
            </Row>
        }
        </>
    )
}

const CustomNoStats = connectStats(NoStats)
// No Results

// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Custom sortby
// Dropdown react select styles
const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
          ...styles,
          backgroundColor: isFocused ? "#005480" : null,
          color: isFocused ? "#ffffff" : "#575757"
      };
  },
  control: styles => ({
      ...styles,
      backgroundColor: null,
      border: 0,
      paddingLeft: 0,
      outline: 0,
      boxShadow: 'none',
      color: '#005480',
      fontSize: '1.25rem',
      fontWeight:'normal'
  }),
  valueContainer: (styles) => ({
      ...styles,
      fontSize: '1.25rem',
      paddingLeft: 0,
      lineHeight: '30px',
      fontWeight:'normal',
      cursor: 'pointer'
  }),
  dropdownIndicator: (styles) => ({
      ...styles,
      color: "#ffffff",
  }),
  indicatorsContainer: (styles) => ({
      ...styles,
      color: '#ffffff',
      cursor: 'pointer'
  }),
  indicatorSeparator: () => null,
  placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: '#515862',
          marginLeft: 0
      }
  },
}

const DownIndicator = () => (
  <>
      <i className={ "icon icon-dropdown-arrow" }></i>
  </>
)
// Dropdown react select styles

const CustomSortBy = ({ items, refine, currentRefinement }) => (
  <div className="search-select-box">
    <Select
        options={items}
        defaultValue={find(items, ['value', currentRefinement])}
        placeholder="Highest Price"
        classNamePrefix={"select-opt"}
        isSearchable={false}
        onChange={(e) => {refine(e.value, e.label)}}
        styles={customStyles}
        components={{ DropdownIndicator: () => <DownIndicator /> }}
    />
  </div>
);

const CustomSortByFilter = connectSortBy(CustomSortBy);
// Custom sortby

// custom refinementlist
const CustomRefinementList = connectRefinementList(({ items, refine }) => (
  <ul className="refine-search-left">
    {items.map(item => (
      <li key={item.label}>
        <label className="refine-check-wrapper">
          {
           capitalize(item.label.replace(/-/g,' '))
          }
          <input type="checkbox" checked={item.isRefined} onChange={() => refine(item.value)} />
          <span className="refine-checkmark"></span>
        </label>
      </li>
    ))}
  </ul>
));
// custom refinementlist

// Create URL
export const createURL = (props, state, refinementList, includeLet) => {
    let myarea = state.menu

    const isDefaultRoute =
    !state.query &&
    !state.SortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.bedroom &&
    (refinementList && !refinementList.length === 0)

    if (isDefaultRoute) {
        return ""
    }

    var areaPath = "in-london/"
    var bedroomPath = ""
    var pricePath = ""
    var pagePath = ""
    var propertypath = ""
    var sortPath = ""
    var mustIncludePath = ""
    var includeLetPath = ""

    if (state.query) {
        if (state.query) {
          areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
        }
    }

    const queryParameters = {}

    // Property path
    if (state.menu) {
        if (myarea["building"]) {
            propertypath = "type-" + myarea["building"] + "/"
        }
    }
    // Property path

    // Bedrooms & Price range path
    if (state.range) {
        // Bedrooms
        if (state.range.hasOwnProperty('bedroom') && state.range.bedroom.max === 0) {
            bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
        }
        if (state.range.bedroom) {
            if (state.range.bedroom.min) {
                bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
            }
        }

        // Price
        if (state.range.price) {
            if (state.range.price.min && state.range.price.max) {
                pricePath = "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
            } else if (state.range.price.max) {
                pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
            } else if (state.range.price.min) {
                pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
            }
        }
    }
    // Bedrooms & Price range path

    // Sort path
    if(state.sortBy) {
        if( state.sortBy === index_name+"_price_asc" ) {
            sortPath = "sortby-price-asc/";
        }
        if( state.sortBy === index_name+"_price_desc" ) {
            sortPath = "sortby-price-desc/";
        }
        if( state.sortBy === index_name+"_most_recent" ) {
          sortPath = "sortby-most-recent/";
        }
    }
    // Sort path

    // Page path
    if (state.page && state.page !== 1) {
        pagePath = `page-${state.page}/`
    }
    // Page path

    // Must include path
    if (refinementList) {
      if (refinementList && refinementList.length > 0) {
        mustIncludePath = `with-${refinementList.map(encodeURIComponent).join('-and-')}/`
      }
    }
    // Must include path

    // Include Let path
    if(!includeLet) {
      includeLetPath="available/"
    }
    // Include Let path

    const queryString = qs.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: "repeat",
    })

    let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${mustIncludePath}${includeLetPath}${queryString}${pagePath}${sortPath}`

    if ("/" === myUrl.substr(myUrl.length - 1))
      myUrl = myUrl.substr(0, myUrl.length - 1)

    return `/property/new-homes/to-rent/${myUrl}`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState, mustIncludes, includeLet) => {
  if(searchState) {
    return `${createURL(props, searchState, mustIncludes, includeLet)}`
  } else {
    return ""
  }
}

const urlToSearchState = location => {
    if (location) {
        var query = ""
        let pathUri_main = location.pathname.split("/property/new-homes/to-rent")

        var areaVal = ""
        var bedVal = 10
        var minpriceVal = ""
        var maxpriceVal = ""
        var pageVal = ""
        var propertyval = ""
        var sortVal = ""
        var sortVal_filt = ""
        var mustIncludeVal = ""
        var statusVal = ""

        if (pathUri_main[1]) {
            // following could be regexp
            let pathUri = pathUri_main[1].split("/")

            //lets loop
            for (let vi = 1; vi <= pathUri.length; vi++) {
                // check for area
                if (typeof pathUri[vi] === "undefined") {
                    continue
                }

                // Property type
                if (pathUri[vi].indexOf("type-") >= 0) {
                    propertyval = pathUri[vi].replace("type-", "")
                }

                // Area
                if (pathUri[vi].indexOf("in-") == 0) {
                    areaVal = pathUri[vi].replace("in-", "")
                }

                // Price
                if (
                    pathUri[vi].indexOf("between-") >= 0 ||
                    pathUri[vi].indexOf("over-") >= 0 ||
                    pathUri[vi].indexOf("under-") >= 0
                ) {
                    let priceFilt1 = pathUri[vi].split("over-")
                    if (priceFilt1[1]) {
                    minpriceVal = priceFilt1[1]
                    }
                    let priceFilt2 = pathUri[vi].split("under-")
                    if (priceFilt2[1]) {
                    maxpriceVal = priceFilt2[1]
                    }
                    let priceFilt3 = pathUri[vi].split("between-")
                    if (priceFilt3[1]) {
                    let priceFilt4 = priceFilt3[1].split("-and-")
                    minpriceVal = priceFilt4[0]
                    maxpriceVal = priceFilt4[1]
                    }
                }

                // Bedrooms
                if (pathUri[vi].indexOf("-and-more-") >= 0) {
                    bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
                }

                if (pathUri[vi].indexOf("studio") >= 0) {
                    // its studio bedroom
                    bedVal = 0
                }

                if (pathUri[vi].indexOf("available") >= 0) {
                    // its studio bedroom
                    statusVal = "To Let"
                }

                // Sort by
                if (pathUri[vi].indexOf("sortby-") >= 0) {
                    sortVal_filt = pathUri[vi].replace("sortby-", "")

                    if(sortVal_filt === "price-asc") {
                      sortVal = index_name+"_price_asc"
                    }

                    if(sortVal_filt === "price-desc") {
                      sortVal = index_name+"_price_desc"
                    }

                    if(sortVal_filt === "most-recent") {
                      sortVal = index_name+"_most_recent"
                    }
                }

                // Must Include
                if (pathUri[vi].indexOf("with-") >= 0) {
                  var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
                  mustIncludeVal = mustIncludeArrfilt.split("-and-")
                }

                // Page
                if (pathUri[vi].indexOf("page") >= 0) {
                    pageVal = pathUri[vi].replace("page-", "")
                }
            }
        }
    }

    // Property type
    if (propertyval) {
        query += `&menu[building]=` + propertyval
    }

    // Area
    if (areaVal) {
        areaVal = areaVal.toLowerCase();
        if(areaVal.length < 4) {
          areaVal = '"'+areaVal+'"'
        }
        if (areaVal == "london") query += `&query=`
        else query += `&query=` + areaVal
    }

    // Price
    if (minpriceVal) {
        query += `&range[price][min]=` + minpriceVal
    }

    if (maxpriceVal) {
        query += `&range[price][max]=` + maxpriceVal
    }

    // Bedrooms
    if (bedVal == 0) {
        query += `&range[bedroom][min]=` + 0
    } else if (bedVal < 10) {
        query += `&range[bedroom][min]=` + bedVal
    }

    // Must Include
    if (mustIncludeVal) {
      for(let v=0; v <= mustIncludeVal.length; v++){
          if (typeof mustIncludeVal[v] !== "undefined") {
              query += `&refinementList[must_include]=` + mustIncludeVal[v]
          }
      }
    }

    // Must Include
    if (statusVal) {
        query += `&refinementList[status]=` + statusVal
    }

    // Page
    if (pageVal) {
        query += `&page=${pageVal}`
    }

    // Sort by
    if (sortVal) {
      query += `&sortBy=` + sortVal
    }

    return qs.parse(query)
}
// URL to searchstate

class PropertySearchNewHomesRentals extends React.Component {
  setMustInclude = (val) => {
    this.setState({ mustIncludes: val })
  }
    //
    state = {
        searchState: urlToSearchState(this.props.location),
        userObjects: [],
        mapview: false,
        includeLet: false,
        mustIncludes: [],
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
          if (this.props.location.pathname.includes("with-") || this.props.location.pathname.includes("include-")) {
            let pathUri_main = this.props.location.pathname.split("/property/new-homes/to-rent")
            if (pathUri_main[1]) {
              let pathUri = pathUri_main[1].split("/");
              let includeUrls = pathUri.filter(c => c.includes("with"))
              if(includeUrls.length > 0) {
                  let includeUrl = includeUrls[0];
                  var mustIncludeArrfilt = includeUrl.replace("with-", "")
                  let vals = mustIncludeArrfilt.split("-and-")
                  this.setState({ mustIncludes: vals })
                }
                if(pathUri.includes("available")) {
                  this.setState({ includeLet: false })
                }
              }
          }
            this.setState({ searchState: urlToSearchState(this.props.location) })
        }
    }

    onSearchStateChange = searchState => {
        clearTimeout(this.debouncedSetState)
            this.debouncedSetState = setTimeout(() => {
              if (typeof searchState.page !== "undefined") {
                let newState = this.state.searchState;
                newState["page"] = searchState.page;
                navigate(searchStateToUrl(this.props, newState, this.state.mustIncludes, this.state.includeLet), searchState)
              } else {}
            // navigate(searchStateToUrl(this.props, searchState), searchState)
            // navigate(searchStateToUrl(this.props, searchState, this.state.mustIncludes), searchState)
            }, updateAfter)
        this.setState({ searchState })
    }

    // onSearchStateChange2 = (searchState) => {
    //   this.setState({ searchState })
    // }
    //

    componentDidMount() {

      let pathUri_main = this.props.location.pathname.split("/property/new-homes/to-rent")
      if (pathUri_main[1]) {
        let pathUri = pathUri_main[1].split("/");
        let includeUrls = pathUri.filter(c => c.includes("with"))
        if(includeUrls.length > 0) {
            let includeUrl = includeUrls[0];
            var mustIncludeArrfilt = includeUrl.replace("with-", "")
            let vals = mustIncludeArrfilt.split("-and-")
            this.setState({ mustIncludes: vals })
          }
          if(pathUri.includes("available")) {
            this.setState({ includeLet: false })
          }
        }
        // Must Include


      $(document).mouseup(function(e)
      {
          var container = $(".dropdown-exp");

          // if the target of the click isn't the container nor a descendant of the container
          if (!container.is(e.target) && container.has(e.target).length === 0)
          {
          $(".dropdown-exp").removeClass("active");
          }
      });


      if(token) {
        getUserObjects().then(
          (userObjectsData) =>{
            this.setState({ userObjects: userObjectsData.data.data })
          }
        );
      }

      if ( (window.location.href).indexOf('property\/new-homes\/to-rent') ) {

            // Infinite properties scroll
            $(window).scroll(function() {
              if ( $("#myprevbut").length ) {
                var top_of_element = $("#myprevbut").offset().top;
                var bottom_of_element = $("#myprevbut").offset().top + $("#myprevbut").outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();

                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                  $("#myprevbut").trigger( "click" );
                }
              }

            });
            // Infinite properties scroll
        }

        window.addEventListener("scroll", this.handleScroll, true)


        if(sessionStorage.getItem('searchindex') != null){
          if(sessionStorage.getItem('tabView') != null && sessionStorage.getItem('tabView') === 'mapview'){
            $(".results-view").addClass("map-veiw-content");
            }
          var idelement = "myRentProp"+sessionStorage.getItem('searchindex');
          setTimeout(function() {
            var elmnt = document.getElementById(idelement.toString());
            if(elmnt){
            elmnt.scrollIntoView();
            sessionStorage.removeItem('searchindex')
            }
        }, 1);
        }
        else {
           // load on top
        $(document).ready(function(){
          $("html, body").scrollTop(0)
        });
        // load on top
        }


        // Info button seo text
        $(".icon-finder").click(function() {
          $(".search_text").toggleClass("active")
        })
        // Info button seo text

        // Let filter
        // $( ".filtersearchsoldlet" ).insertAfter( ".defaultsoldfilter" );
        $( ".must_include_let" ).insertAfter( ".property_let_fitler" );
        $( ".must_include_let_mob" ).insertAfter( ".property_let_fitler_mob" );
        // Let filter

        // Must include filter
        // $( ".filterMustIncludeOutsideSpace" ).insertAfter( ".defaultfilteroutsidespace" );
        // $( ".filterMustIncludeParking" ).insertAfter( ".defaultfilterparking" );
        // $( ".filterMustIncludeChainFree" ).insertAfter( ".defaultfilterchainfree" );
        // Must include filter
    }

    // Filter active onscroll
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }

    handleScroll = () => {
        lastScrollY = window.scrollY

    if (lastScrollY > 200) {
        activeHeader = "active-scroll"
    } else if (lastScrollY < 200) {
        activeHeader = ""
    }

        this.setState({ activeHeader })
    }
    // Filter active onscroll

    // Sales & Rent
    onClickBuy = (e) => {
        navigate(`/property/new-homes/for-sale/in-london/`)
    }

    onClickRent = (e) => {
        navigate(`/property/new-homes/to-rent/in-london/`)
    }
    // Sales & Rent

    // Clear filter
    clearFilter = (e) => {
      navigate(`/property/new-homes/to-rent/in-london/`)
    }
    // Clear filter

    // Map & List View
    listView = (e) => {
        $(".results-view").removeClass("map-veiw-content");
        sessionStorage.setItem('tabView', 'listview')
    }

    mapView = (e) => {
        $(".results-view").addClass("map-veiw-content");
        sessionStorage.setItem('tabView', 'mapview')
    }

    listViewMobile = (e) => {
      $(".results-view").removeClass("map-veiw-content");
      $(".list-mobile").removeClass("active")
      $(".map-mobile").addClass("active")
      this.setState({mapview: false});
      sessionStorage.setItem('mobileView', 'listview')
    }

    mapViewMobile = (e) => {
      $(".results-view").addClass("map-veiw-content");
      $(".list-mobile").addClass("active")
      $(".map-mobile").removeClass("active")
      this.setState({mapview: true});
      sessionStorage.setItem('mobileView', 'mapview')
    }
    // Map & List View

    // Filters
    fitlerDisplay = (e) => {
        $(".dropdown-exp").toggleClass("active");
    }
    // Filters

    // Refine your results
    refineFilterResults = (e) => {
      $(".filter-toggle").toggleClass("active");
      $(".filters-form").toggleClass("active-filter");
    }
    // Refine your results

    render () {
        const searchParams = parseSearchUrl("lettings", this.props.location.pathname);
        const saveSearchParams = savedSearchParams(searchParams);
        const h1 = propertyH1(searchParams);
        const areaName = searchParams.areas;
        const pType = searchParams.pType;

        const isPageLoadBg = (typeof this.props.path === "undefined");

        let titleh1 = h1//.replace("Apartment", "Apartments").replace("House", "Houses").replace("Bungalow", "Bungalows").replace("New-home", "New-homes").replace("parking", "Parking").replace("chain", "Chain").replace("outside", "Outside").replace(/-/g, " ")

        titleh1 = titleh1.trim();

        let search_summarytxt = titleh1.charAt(0).toLowerCase() + titleh1.slice(1);

        return (
            <>
                <HeaderTwo  page="results"/>

                {!isPageLoadBg &&
                  <NewhomeSeoResults title={this?.props?.pageContext?.title ? this?.props?.pageContext?.title : h1} description={this?.props?.pageContext?.desc} searchParams={searchParams} location={this?.props?.location?.pathname != "/property/new-homes/to-rent/" ? this?.props?.location : ""} />
                }

                    <div className="content result-list-content">
                        <div className={activeHeader + " search-filters"}>
                          <SearchResultsFilterNewRentals />
                        </div>
                        <InstantSearch
                            indexName={index_name}
                            searchClient={searchClient}
                            searchState={this.state.searchState}
                            onSearchStateChange={this.onSearchStateChange}
                            createURL={createURL}
                            routing="true"
                        >

                            <div className={"d-none"}>
                                <div className="d-none">
                                    <RefinementList
                                        attribute="search_type"
                                        defaultRefinement={["lettings"]}
                                    />
                                    <RefinementList
                                        attribute="publish"
                                        defaultRefinement={["true"]}
                                    />
                                    <RefinementList
                                        attribute="department"
                                        defaultRefinement={["new_developments"]}
                                    />
                                    <RefinementList
                                        attribute="status"
                                        defaultRefinement={["To Let","Let"]}
                                        className="status_for_sale"
                                    />
                                    {this.props.location.pathname.includes("type-house") &&
                                    <RefinementList
                                        attribute="building"
                                        defaultRefinement={["house", "semi-detached-house"]}
                                    />}
                                    {this.props.location.pathname.includes("available") &&
                                    <RefinementList
                                      attribute="status"
                                      defaultRefinement={["Let"]}
                                    />}
                                    <RefinementList
                                        attribute="must_include"
                                        defaultRefinement={this.state.mustIncludes}
                                    />
                                </div>
                                {/* <Link className={isMobileOnly ? "filter-toggle active d-flex justify-content-between align-items-center d-xl-none text-uppercase" : "filter-toggle d-flex justify-content-between align-items-center d-xl-none text-uppercase"} to="#" onClick={this.refineFilterResults}> */}
                                <Link className={"filter-toggle d-flex justify-content-between align-items-center d-xl-none text-uppercase"} to="#" onClick={this.refineFilterResults}>
                                    <span>refine your results</span>
                                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.98403 8.00005L0.960028 15.04C0.752028 15.248 0.400028 15.248 0.176028 15.04C-0.0319716 14.832 -0.0319716 14.48 0.176028 14.256L6.43203 8.00005L0.160028 1.72805C-0.0479717 1.52005 -0.0479717 1.16805 0.160028 0.944047C0.368028 0.736047 0.720028 0.736047 0.944028 0.944047L7.98403 8.00005Z" fill="#ffffff"/>
                                    </svg>
                                </Link>
                                {/* <Form  className={isMobileOnly ? "filters-form active-filter d-xl-flex justify-content-between" : "filters-form d-xl-flex justify-content-between"}> */}
                                <Form  className={"filters-form d-xl-flex justify-content-between"}>
                                <Form.Group className="radios-wrp">
                                    <div className="radio-boxes d-flex">
                                        <a href="javascript:void(0)" className="property_filter_btn" onClick={this.onClickBuy}>Buy</a>
                                        <a href="javascript:void(0)" className="property_filter_btn active" onClick={this.onClickRent}>Rent</a>
                                    </div>
                                </Form.Group>

                                <SearchBox />

                                <Form.Group className="price-wrap d-flex justify-content-between align-items-center">
                                    <div className="price-box">
                                        <CustomminpriceSelectbox attribute="price" />
                                    </div>
                                    <div className="price-box">
                                        <CustommaxpriceSelectbox attribute="price" />
                                    </div>
                                </Form.Group>

                                <Form.Group className="bed-wrap select-grp-opt d-flex align-items-center">
                                    <div className="select-group">
                                      <CustombedroomSelectbox attribute="bedroom" />
                                    </div>
                                </Form.Group>

                                <Form.Group className="select-grp-opt d-flex align-items-center">
                                    <div className="select-group">
                                    <CustombuildingSelectbox attribute="building" />
                                    </div>
                                </Form.Group>

                                <Form.Group className="select-grp-opt fil-opt d-flex align-items-center">
                                    <Link  className="filter-dropdown" to="#" onClick={this.fitlerDisplay}><span>Filters </span> <i class="icon-arrow-dropdown"></i></Link>
                                    <div className="dropdown-exp">
                                        {['checkbox'].map((type) => (
                                            <div key={`custom-inline-${type}`} className="mb-3">
                                                {/* <div className="property-filter_check">
                                                  <ToggleRefinement
                                                    attribute="status"
                                                    label="Include Let"
                                                    value={['Let']}
                                                  />
                                                </div> */}
                                                <div className="must-include">Must Include</div>
                                                <ul className="offer-list d-flex flex-wrap">
                                                  <li>
                                                    <Form.Group className="mb-0 must-include-check" controlId="Outside Space">
                                                        <Form.Check
                                                            type="checkbox"
                                                            label={"Outside Space"}
                                                            checked={this.state.mustIncludes.includes("outside-space")}
                                                            onChange={(e) => {
                                                              if(this.state.mustIncludes.includes("outside-space")) {
                                                                this.setState({ mustIncludes: this.state.mustIncludes.filter(c => c !== "outside-space")}, () => {
                                                                  navigate(createURL({}, this.state.searchState, this.state.mustIncludes))
                                                                })
                                                              } else {
                                                                this.setState({ mustIncludes: this.state.mustIncludes.concat("outside-space")}, () => {
                                                                  navigate(createURL({}, this.state.searchState, this.state.mustIncludes))
                                                                })
                                                              }

                                                            }}
                                                        />
                                                    </Form.Group>
                                                  </li>
                                                  <li>
                                                    <Form.Group className="mb-0 must-include-check" controlId="Chain free">
                                                        <Form.Check
                                                            type="checkbox"
                                                            label={"Chain free"}
                                                            checked={this.state.mustIncludes.includes("chain-free")}
                                                            onChange={(e) => {
                                                              if(this.state.mustIncludes.includes("chain-free")) {
                                                                this.setState({ mustIncludes: this.state.mustIncludes.filter(c => c !== "chain-free")}, () => {
                                                                  navigate(createURL({}, this.state.searchState, this.state.mustIncludes))
                                                                })
                                                              } else {
                                                                this.setState({ mustIncludes: this.state.mustIncludes.concat("chain-free")}, () => {
                                                                  navigate(createURL({}, this.state.searchState, this.state.mustIncludes))
                                                                })
                                                              }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                  </li>
                                                  <li>
                                                    <Form.Group className="mb-0 must-include-check" controlId="Parking">
                                                        <Form.Check
                                                            type="checkbox"
                                                            label={"Parking"}
                                                            checked={this.state.mustIncludes.includes("parking")}
                                                            onChange={(e) => {
                                                              if(this.state.mustIncludes.includes("parking")) {
                                                                this.setState({ mustIncludes: this.state.mustIncludes.filter(c => c !== "parking")}, () => {
                                                                  navigate(createURL({}, this.state.searchState, this.state.mustIncludes))
                                                                })
                                                              } else {
                                                                this.setState({ mustIncludes: this.state.mustIncludes.concat("parking")}, () => {
                                                                  navigate(createURL({}, this.state.searchState, this.state.mustIncludes))
                                                                })
                                                              }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                  </li>
                                                </ul>
                                                <div className="search-clear-filter">
                                                  <a href="/property/new-homes/to-rent/in-london/">Clear Filter</a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                                </Form>
                            </div>

                            <section className="result-map results-view">
                                <div className="list-view-wrap">
                                <Container>
                                    <div className ="results-detail">
                                      <InView {...inViewOptions}>
                                        {({ ref, inView }) => (
                                        <motion.div
                                          className="result-head"
                                          ref={ref}
                                          initial="hidden"
                                          animate={inView ? "visible" : "hidden"}
                                          variants={containerVariants}
                                        >
                                        <div className="d-md-flex justify-content-between">
                                            <div className="result-info">
                                            <h1 className="search-results-h1">{titleh1} <strong>(<CustomStats />)</strong> <i className="icon-finder"></i> <span className="wishlist-icon-search-heading"><SaveProperty type="search" searchParams={saveSearchParams} userObjects={this.state.userObjects}/></span></h1>
                                                {/* <SaveProperty type="search" searchParams={saveSearchParams} userObjects={this.state.userObjects}/> */}
                                            </div>
                                            <ul className="view-list d-md-none d-flex align-items-center">
                                                <li>
                                                  <div className="property_let_fitler_mob"></div>
                                                </li>
                                                <li className="property_sortby">
                                                  <CustomSortByFilter
                                                    defaultRefinement={index_name}
                                                    className="search-sory-by"
                                                    items={[
                                                      { value: index_name+"_price_desc", label: "Highest Price First" },
                                                      { value: index_name+"_price_asc", label: "Lowest Price First" },
                                                      { value: index_name+"_most_recent", label: "Most Recent First" },
                                                    ]}
                                                  />
                                                  {/* <div className="search-select-box sortby-dropdown">
                                                    <Select
                                                        name="sortby"
                                                        options={[
                                                          { value: index_name+"_price_desc", label: "Highest Price First" },
                                                          { value: index_name+"_price_asc", label: "Lowest Price First" },
                                                          { value: index_name+"_most_recent", label: "Most Recent First" },
                                                        ]}
                                                        placeholder="Highest Price First"
                                                        classNamePrefix={"select-opt"}
                                                        isSearchable={false}
                                                        styles={customStyles}
                                                        components={{ DropdownIndicator: () => <DownIndicator /> }}
                                                    />
                                                  </div> */}
                                                </li>
                                                <li className="list-ctrl list-mobile align-items-center"><a href="javascript:void(0)" onClick={this.listViewMobile}> Grid View</a></li>
                                                <li className="map-ctrl map-mobile align-items-center active"><a href="javascript:void(0)" onClick={this.mapViewMobile}>Map View</a></li>
                                            </ul>
                                            <ul className="view-list d-none d-md-block">
                                                <li>
                                                  <div className="property_let_fitler"></div>
                                                </li>
                                                <li className="property_sortby">
                                                    <CustomSortByFilter
                                                        defaultRefinement={index_name}
                                                        className="search-sory-by"
                                                        items={[
                                                          { value: index_name+"_price_desc", label: "Highest Price First" },
                                                          { value: index_name+"_price_asc", label: "Lowest Price First" },
                                                          { value: index_name+"_most_recent", label: "Most Recent First" },
                                                        ]}
                                                    />
                                                    {/* <div className="search-select-box sortby-dropdown">
                                                      <Select
                                                          name="sortby"
                                                          options={[
                                                            { value: index_name+"_price_desc", label: "Highest Price First" },
                                                            { value: index_name+"_price_asc", label: "Lowest Price First" },
                                                            { value: index_name+"_most_recent", label: "Most Recent First" },
                                                          ]}
                                                          placeholder="Highest Price First"
                                                          classNamePrefix={"select-opt"}
                                                          isSearchable={false}
                                                          styles={customStyles}
                                                          components={{ DropdownIndicator: () => <DownIndicator /> }}
                                                      />
                                                    </div> */}
                                                </li>
                                                <li className="list-ctrl"><a href="javascript:void(0)" onClick={this.listView}><i className="icon-list"></i> <span className="d-none d-xl-inline">List</span> </a></li>
                                                <li className="map-ctrl"><a href="javascript:void(0)" onClick={this.mapView}><i className="icon-map-active"></i> <span className="d-none d-xl-inline">Map</span></a></li>
                                            </ul>
                                        </div>
                                        <p className="desc d-none d-md-block search_text">Discover a wide range of {search_summarytxt} with Martyn Gerrard Estate Agents. To filter these results, use our filter above, or for more information about {pType} property in <div className="property_area_captialize">{areaName.replace("%20"," ")}</div>, please contact us.</p>
                                        </motion.div>
                                        )}
                                      </InView>
                                        <div id="property_list_results" className="cards-wrp property_list_results">
                                          <CustomInfiniteHits location={this.props.location} userObjects={this.state.userObjects} />
                                          <CustomNoStats location={this.props.location} userObjects={this.state.userObjects} />
                                        </div>
                                    </div>
                                </Container>
                                </div>

                                <div className="map-scroll map-view-wrap">
                                  { (!isMobile) &&
                                      <MapResultsDesktop ptype="myRentProp" />
                                  }
                                  { (isMobile && this.state.mapview) &&
                                      <>
                                        <Configure
                                          hitsPerPage={2000}
                                        />
                                        <MapResultsDevice />
                                      </>
                                  }
                                </div>
                            </section>
                        </InstantSearch>
                    </div>
                    <CanHelp />
                <Footer searchtype='newhomerentals' popularSearch='newhomespropertyresults' searchBedroomfield={searchParams.bedrooms} searchPtype={searchParams.type} Searcharea={searchParams}/>
            </>
        )
    }
}

export default PropertySearchNewHomesRentals
